import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import InlineEdit from 'react-edit-inplace';
import ls from 'local-storage';
import ReactGA from 'react-ga-gtm';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.css'
import withReactContent from 'sweetalert2-react-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import './App.css';

ReactGA.initialize('UA-54958811-3', {
  debug: true,
  titleCase: false,
  gaOptions: {
    userId: 123
  }
});

ReactGA.pageview(window.location.pathname + window.location.search);
console.log(window.location.search);

const rSwal = withReactContent(Swal);

class App extends Component {
  constructor(props) {
    super(props);

    const defaultTabs = () => {
      const isfilm = window.location.host.includes('film.kenlaugui.co');
      const isdesign = window.location.host.includes('design.kenlaugui.co');
      const ishelp = window.location.host.includes('help.dash.kenlauguico.com');
      const filmDash = {
        title: "Film",
        items: [
          { title: "iSpot.tv", url: "https://ispot.tv" },
          { title: "DP", url: "http://dp.kenlaugui.co" },
        ]
      }
      const designDash = {
        title: "Design",
        items: [
          { title: "Designtools Club", url: "https://designtools.club" },
          { title: "Muzli", url: "https://search.muz.li" },
          { title: "Inoreader: Design", url: "https://design3.kenlaugui.co" },
          { title: "Designspiration", url: "https://designspiration.com" },
        ]
      }
      const dashHelp = {
        title: "Let's make shit",
        items: [
          { title: "Tasks", url: "https://gitlab.com/kenlauguico/dash/-/boards/703850" },
          { title: "Figma", url: "https://www.figma.com/file/XUnSZFN6IabJeS2l3mTRQl/Dash?node-id=0%3A1" },
          { title: "Gantt", url: "https://paper.dropbox.com/doc/dash-gantt--A2AfF24Yqde7m0~~n2V5RApgAg-QSljB6gDjp4o5mHpEmm5J" },
        ]
      }
      const defaultDash = {
        title: "Home",
        items: [
          { title: "Search", url: "https://google.com" },
          { title: "Watch", url: "https://youtube.com" },
        ]
      }
      if (isfilm)   return [filmDash];
      if (isdesign) return [designDash];
      if (ishelp)   return [dashHelp];
      return [defaultDash];
    }

    var tabs = ls('tabs') ? ls('tabs') : defaultTabs();

    this.state = {
      tabs: tabs,
      tabIndex: 0,
    };

    this.trackOpenURL = this.trackOpenURL.bind(this);
    this.validateText = this.validateText.bind(this);
    this.addTab = this.addTab.bind(this);
    this.removeTab = this.removeTab.bind(this);
    this.confirmRemoveTab = this.confirmRemoveTab.bind(this);
    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.confirmRemoveItem = this.confirmRemoveItem.bind(this);
    this.editDash = this.editDash.bind(this);
    this.editDashItem = this.editDashItem.bind(this);
  }

  trackOpenURL(url) {
    var tabs = this.state.tabs;
    var tabIndex = this.state.tabIndex;

    ReactGA.event({
      category: tabs[tabIndex].title,
      action: 'Open URL',
      label: url
    });
  }

  validateText(text) {
    return (text.length > 0 && text.length < 64);
  }

  async addTab() {
    const { value: dashName } = await Swal.fire({
      title: 'Create new Dash',
      input: 'text',
      showCancelButton: true,
      cancelButtonColor: 'silver',
    })
    if (dashName) {
      var title = dashName;
      if (!title) return;
      var tabs = this.state.tabs;
      tabs.push({ title: title, items: [] });
      this.setState({ tabs: tabs, tabIndex: tabs.length-1 });
    }
  }

  removeTab() {
    var index = this.state.tabIndex;
    var tabs = this.state.tabs;
    if (index > -1) {
      tabs.splice(index, 1);
      this.setState({
        tabs: tabs,
        tabIndex: this.state.tabIndex-1
      });
    }
  }

  confirmRemoveTab() {
    var i = this.state.tabIndex;
    var tabTitle = this.state.tabs[i].title;
    Swal.fire({
      title: 'Are you sure you want to delete \'' + tabTitle + '\'?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'silver',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.removeTab();
      }
    })
  }

  async addItem() {
    const { value: formValues } = await Swal.fire({
      title: 'Add link',
      html:
        '<input id="swal-input2" class="swal2-input" placeholder="title">' +
        '<input id="swal-input1" class="swal2-input" placeholder="url">',
      showCancelButton: true,
      cancelButtonColor: 'silver',
      preConfirm: () => {
        return [
          document.getElementById('swal-input1').value,
          document.getElementById('swal-input2').value
        ]
      }
    })
    if (formValues) {
      var url = formValues[0];
      var title = formValues[1];
      if (!url) return;
      if (url.split("://").length === 1) url = "http://" + url;
      if (!title) title = url.split(/:\/\/(?:www\.)?/)[1];
      var tabs = this.state.tabs;
      tabs[this.state.tabIndex].items.push({ url: url, title: title });
      this.setState({ tabs: tabs });
    }
  }

  removeItem(index) {
    var tabs = this.state.tabs;
    if (index > -1) {
      tabs[this.state.tabIndex].items.splice(index, 1);
      this.setState({tabs: tabs});
    }
  }

  confirmRemoveItem(index) {
    var i = this.state.tabIndex;
    var itemTitle = this.state.tabs[i].items[index].title;
    Swal.fire({
      title: 'Are you sure you want to delete \'' + itemTitle + '\'?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: 'silver',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.value) {
        this.removeItem(index);
      }
    })
  }

  async editDash(index) {
    var dash = this.state.tabs[index];
    const oldTitle = dash.title;
    const { value: newDashName } = await rSwal.fire({
      title: 'Edit Dash Title',
      input: 'text',
      inputValue: oldTitle,
      showCancelButton: true,
      cancelButtonColor: 'silver',
      footer: <a class="remove" onClick={e => this.confirmRemoveTab() }><FontAwesomeIcon icon={faTrash} size="xs" /> Delete Dash</a>
    })
    if (newDashName) {
      if (!newDashName) return;
      dash.title = newDashName;
      var tabs = this.state.tabs;
      tabs[index] = dash;
      this.setState({ tabs: tabs });
    }
  }

  async editDashItem(index) {
    var dash = this.state.tabs[this.state.tabIndex];
    var dashLink = dash.items[index];
    const { value: formValues } = await rSwal.fire({
      title: 'Edit Dash link',
      html:
        '<input id="swal-input2" class="swal2-input" placeholder="title" value="' + dashLink.title + '">' +
        '<input id="swal-input1" class="swal2-input" placeholder="url" value="' + dashLink.url + '">',
      showCancelButton: true,
      cancelButtonColor: 'silver',
      footer: <a class="remove" onClick={e => this.confirmRemoveItem(index) }><FontAwesomeIcon icon={faTrash} size="xs" /> Delete Dash Link</a>,
      preConfirm: () => {
        return [
          document.getElementById('swal-input1').value,
          document.getElementById('swal-input2').value
        ]
      }
    })
    if (formValues) {
      var url = formValues[0];
      var title = formValues[1];
      if (!url) return;
      if (url.split("://").length === 1) url = "http://" + url;
      if (!title) title = url.split(/:\/\/(?:www\.)?/)[1];
      var tabs = this.state.tabs;
      tabs[this.state.tabIndex].items[index] = ({ url: url, title: title });
      this.setState({ tabs: tabs });
    }
  }

  render() {
    var tabItems = this.state.tabs;
    ls.set('tabs', tabItems);
    var tabs = tabItems.map(tab =>
        <Tab id="tabs">
          { tab.title }
          <FontAwesomeIcon className="edit" icon={faPen} size="xs" onClick={e => this.editDash(tabItems.indexOf(tab)) } />
        </Tab>
      );
    var TabPanels = tabItems.map(tab =>
      <TabPanel>
        <ul id="sitelist">
          { tab.items.map(item =>
            <li className="site">
              <a href={item.url} onClick={ e => this.trackOpenURL(item.url) }>
                <p>{item.title}</p>
              </a>
              <FontAwesomeIcon className="edit" icon={faPen} size="xs" onClick={ e => this.editDashItem(tab.items.indexOf(item)) } />
            </li>
          )}
          <button className="add" onClick={this.addItem}>+</button>
        </ul>
      </TabPanel>
      );
    return (
      <div className="App">
        <Tabs selectedIndex={this.state.tabIndex} onSelect={tabIndex => this.setState({ tabIndex })}>
          <TabList>
            { tabs }
            <button className="add" onClick={this.addTab}>+</button>
          </TabList>
          { TabPanels }
        </Tabs>
      </div>
    );
  }
}

export default App;
